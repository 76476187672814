import { Component, AfterViewInit, ViewChild, Input,ElementRef, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { merge, Observable, of as observableOf, Subject } from 'rxjs';
import { catchError, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MessageService } from 'src/app/services/message/message.service';
import { FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { CoreServiceService } from 'src/app/services/core/core-service.service';
import { environment } from 'src/environments/environment';


export interface searchFilter {
  transmitter: string;
  recipient: string;
  projectId: string;
  dateBefore: any;
  dateDeadline: any;
  dateDeadlineAfter: any;
  dateAfter: any;
  tag: string;
  statut: boolean;
  type: string;
  keyWord: string;
  smartNumber: string;
}

@Component({
  selector: 'app-dashboardcontent',
  templateUrl: './dashboardcontent.component.html',
  styleUrls: ['./dashboardcontent.component.scss']
})

export class DashboardcontentComponent implements AfterViewInit, OnDestroy, OnInit {
 
  messages: any = [];
  dataSource: any;
  resultsLength = 0;
  perPage = 30;
  isLoadingResults = false;
  isRateLimitReached = false;
  lastDate = new Date('01/01/4970');
  filterChoosen = null;
  firstLoad = true;

  displayedColumns: string[] = [
    'Date',
    'Projet',
    'Expéditeur',
    'Destinataire',
    'Sous-canal',
    //'Type',
    'Statut',
    'consult'
  ];
  
  hiddenSearchEngin = true;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Output() EventLaunchSearch = new EventEmitter();
  @Output() EventLaunchSearchDoc = new EventEmitter();
  @Input() ged: boolean = false;

  transmitter = null;
  recipient = null;
  projectId = null;
  dateBefore = null;
  dateAfter = null;
  dateDeadline = null;
  dateDeadlineAfter = null;
  tag = null;
  statut = null;
  type = null;
  keyWord = null;
  smartNumber = null;
  modeDoc = false;
  //contacts: Array<any> = [];
  contactsExp: Array<any> = [];
  contactsDest: Array<any> = [];
  projectLists: Array<any> = [];
  projectTags: any;
  chosenFilter: searchFilter;
  currentUser = null;
  saveCtc = null;
  saveCtcTransmitter = null;
  saveProject = null;
  saveTheme   = null;
  secteur = "";
  hideNav = Number(localStorage.getItem('hideNav'));
  public transmitterCtrl: FormControl = new FormControl();
  public recipientCtrl: FormControl = new FormControl();
  public projectCtrl: FormControl = new FormControl();
  public themeCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
  recentProject = null;
  searchstepface = "init";
  searchsenderface = "Par Éxpéditeur";
  searchrecipientface = "Par Destinataire";
  searchcopyface = "Par Membre en Copie";
  searchthemeface = "Par Thème";
  searchtypeface = "Par Type de Document";
  searchproject = "Par Franchisé";
  searchdateface = "Par Date";
  searchstatusface = "Par Statut de réponse";
  searchnumface = "Par Numéro de discussion";
  searchtypes = [
    {id: "pdf", nom: "Pdf", image: "search-pdf"},
    {id: "excel", nom: "Excel", image: "search-excel"},
    {id: "word", nom: "Word", image: "search-word"},
    {id: "presentation", nom: "Présentation", image: "search-presentation"},
    {id: "image", nom: "image", image: "search-image"},
    {id: "video", nom: "Vidéo", image: "search-video"},
    {id: "http", nom: "Lien", image: "search-lien"}
  ]
  filterprojectf = "alpha";
  filtersenderf = "alpha";
  filterrecipientf = "alpha";
  filtertagf = "alpha";
  searchProjectF = null;
  searchSenderF = null;
  searchRecipientF = null;
  searchCopyF = null;
  searchTagF = null;
  projectInit = [];
  projectSendersInit = [];
  searchRecipientInit = [];
  searchCopyInit = [];
  searchSenderInit = [];
  searchTagInit = [];
  searchresLength = 0;
  targetValue: number = 0;
  intervalId: any;
  private refreshSearchObs = new Subject();
  filterChoosenSearch= null;

  constructor(private location: Location, private coreService: CoreServiceService, private authService: AuthService, private router: Router, private route: ActivatedRoute, private messageService: MessageService) { 
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    delete currentUser.token;
    delete currentUser.refresh_token;
    delete currentUser.roles;
    this.currentUser = currentUser;
    this.route.queryParams.subscribe(params => {
      this.recentProject = params['projet'];
    });
  }


  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnInit(){
    this.resultsLength = 0; 
    this.searchresLength = 0;
    this.getAllMessages(null);
    this.secteur = localStorage.getItem('secteur');
  
    this.getContact();
    //this.getReceiver(null);
    this.loadProject(); 

    this.authService.closeBloc.subscribe((term) =>{ 
      if(term == 1){
        this.resetSearch();
      }
      //console.log('dans le smarnumber idmessage ==> '+idMessage);
    });

    this.authService.obprojectChosen.subscribe((idMessage) =>{ 
      this.smartNumber = idMessage;
      //console.log('dans le smarnumber idmessage ==> '+idMessage);
    });

    this.coreService.toggleSidebar.subscribe((res) => {
      this.hideNav = Number(res);
    });

    this.ged = false;
    this.hiddenSearchEngin = true;

  }

  redirectToHistoInstant(){
    this.router.navigate(['/dashboardinst']);
  }

  ngAfterViewInit() {  

    this.recipientCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterRecipient();
      });

    this.transmitterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterTransmitter();
    });


    this.projectCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProject();
      });

    this.themeCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.themeProject();
      });
   

  }



 loadFilterElement(){
    //console.log("on loadFilterElement");
    if (this.firstLoad == true) {
      this.getContact();
      this.loadProject();
    }
    this.firstLoad = false;
  }

  fcthiddenSearchEngin(){
    if (this.hiddenSearchEngin == false)
      this.hiddenSearchEngin = true;
    else
      this.hiddenSearchEngin = false;
  }

  protected filterRecipient() {
    if(this.saveCtc == null){
      this.saveCtc = this.contactsDest;
    }

    let search = this.recipientCtrl.value;
    if (!search) {
      this.contactsDest = this.saveCtc;
      return;
    } else {
      search = search.toLowerCase();
      this.contactsDest = this.saveCtc.filter(bank => bank.givenName.toLowerCase().indexOf(search) > -1 || bank.familyName.toLowerCase().indexOf(search) > -1 );
    }
  }

  protected filterTransmitter(){
    if(this.saveCtcTransmitter == null){
      this.saveCtcTransmitter = this.contactsExp;
    }

    let search = this.transmitterCtrl.value;
    if (!search) {
      this.contactsExp = this.saveCtcTransmitter;
      return;
    } else {
      search = search.toLowerCase();
      this.contactsExp = this.saveCtcTransmitter.filter(bank => bank.givenName.toLowerCase().indexOf(search) > -1 || bank.familyName.toLowerCase().indexOf(search) > -1 );
    }
  }

  protected filterProject() {
    if(this.saveProject == null){
      this.saveProject = this.projectLists;
    }

    let search = this.projectCtrl.value;
    if (!search) {
      this.projectLists = this.saveProject;
      return;
    } else {
      search = search.toLowerCase();
      this.projectLists = this.saveProject.filter(bank => bank.libelle.toLowerCase().indexOf(search) > -1);
    }
  }

  protected themeProject() {
    // console.log("calling themeProject");
    if(this.saveTheme == null){
      this.saveTheme = this.projectTags;
      // console.log("in themeProject", this.saveTheme);
    }else{
      // console.log("else themeProject", this.projectTags);
    }

    let search = this.themeCtrl.value;
    if (!search) {
      this.projectTags = this.saveTheme;
      return;
    } else {
      search = search.toLowerCase();
      this.projectTags = this.saveTheme.filter(bank => bank.libelle.toLowerCase().indexOf(search) > -1);
    }
  }

  onSearchProjectF() {
    if (this.searchProjectF.trim() === '' || this.searchProjectF == null) {
      this.searchProjectF = null;
      this.projectInit = this.projectLists;
      this.filterProjectF(this.filterprojectf);
    } else {
      var search = this.searchProjectF.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      this.projectInit = this.projectLists.filter(bank => bank.libelle.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1);
    }
  }

  onSearchSenderF() {
    if (this.searchSenderF.trim() === '' || this.searchSenderF == null) {
      this.searchSenderF = null;
      this.searchSenderInit = this.contactsExp;
      this.filterSendersF(this.filtersenderf);
    } else {
      var search = this.searchSenderF.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      this.searchSenderInit = this.contactsExp.filter(bank => bank.fullName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 || bank.familyName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 );
    }
  }

  onSearchRecipientF() {
    if (this.searchRecipientF.trim() === '' || this.searchRecipientF == null) {
      this.searchRecipientF = null;
      this.searchRecipientInit = this.contactsDest;
      this.filterRecipientF(this.filterrecipientf);
    } else {
      var search = this.searchRecipientF.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      this.searchRecipientInit = this.contactsDest.filter(bank => bank.fullName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 || bank.familyName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 );
    }
  }

  onSearchTagF() {
    if (this.searchTagF.trim() === '' || this.searchTagF == null) {
      this.searchTagF = null;
      this.searchTagInit = this.projectTags;
      this.filterTagF(this.filtertagf);
    } else {
      var search = this.searchTagF.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      this.searchTagInit = this.projectTags.filter(bank => bank.libelle.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1);
    }
  }

  //************* */
  filterProjectF(val : any){
    this.filterProjectF = val;
    if(val == "date"){
        this.projectInit.sort((a, b) => {
          if ( a.createdAt > b.createdAt ){
            return -1;
          }
          if ( a.createdAt < b.createdAt ){
            return 1;
          }
          return 0;
        })
    } else {
      this.projectInit.sort((a, b) => {
        if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
          return -1;
        }
        if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
          return 1;
        }
        return 0;
      })
    }
  }

  filterSendersF(val : any){
    this.filterSendersF = val;
    if(val == "date"){
        this.searchSenderInit.sort((a, b) => {
          if ( a.user.createdAt > b.user.createdAt ){
            return -1;
          }
          if ( a.user.createdAt < b.user.createdAt ){
            return 1;
          }
          return 0;
        })
    } else {
      this.searchSenderInit.sort((a, b) => {
        if ( a.user.fullName.toLowerCase() < b.user.fullName.toLowerCase() ){
          return -1;
        }
        if ( a.user.fullName.toLowerCase() > b.user.fullName.toLowerCase() ){
          return 1;
        }
        return 0;
      })
    }
  }

  filterRecipientF(val : any){
    this.filterRecipientF = val;
    if(val == "date"){
        this.searchRecipientInit.sort((a, b) => {
          if ( a.user.createdAt > b.user.createdAt ){
            return -1;
          }
          if ( a.user.createdAt < b.user.createdAt ){
            return 1;
          }
          return 0;
        })
    } else {
      this.searchRecipientInit.sort((a, b) => {
        if ( a.user.fullName.toLowerCase() < b.user.fullName.toLowerCase() ){
          return -1;
        }
        if ( a.user.fullName.toLowerCase() > b.user.fullName.toLowerCase() ){
          return 1;
        }
        return 0;
      })
    }
  }

  filterTagF(val : any){
    this.filtertagf = val;
    if(val == "date"){
        this.searchTagInit.sort((a, b) => {
          if ( a.createdAt > b.createdAt ){
            return -1;
          }
          if ( a.createdAt < b.createdAt ){
            return 1;
          }
          return 0;
        })
    } else {
      this.searchTagInit.sort((a, b) => {
        if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
          return -1;
        }
        if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
          return 1;
        }
        return 0;
      })
    }
  }
  

  dataMap(data: any, first = null) {

    var strId = data['project']['@id']; 
    var splittedID = strId.split("/"); 
    var idProject = splittedID[splittedID.length-1];  
    var listTag : any[] = data['tags'];
    var date = data['motionAt']; 
    var isNewDay = this.isNewDay(date);

    var typeMessage = 'QUESTION';

    if (data['type'] == 'QUESTION') {
      typeMessage = 'Question';
    }
    else if (data['type'] == 'COMMENT'){
      typeMessage = 'Commentaire';
    }
    else if (data['type'] == 'ANSWER'){
      typeMessage = 'Réponse';
    }

    const message: any = {
      'id': idProject,
      'project': data['project'],
      'first': first,
      'tag': (listTag[0] ? listTag[0].libelle  : '' ),
      'date': date,
      'typeParse': typeMessage,
      'type': data['type'],
      'private': data['isPrivate'],
      'sender': (data['author'] ? data['author']['fullName'] : ''),
      'recipient': (data['recipient'] ? data['recipient']['fullName'] : ''),
      'read': data['isMessageAnswered'],
      'deadline': data['deadline'],
      'number': data['number'],
      'smartNumber': data['smartNumber'],
      'filegeneric': data['filegeneric'],
      'isMessageDisabled': data['isMessageDisabled'],
      'isMessageAnswered': (data['isMessageAnswered'] === true ? 'Répondu' : 'Non répondu'),
      'isNewDay' : isNewDay
    };

    return message;
  }

 public getAllMessages(searchChoose) {

    this.paginator.pageIndex = 0;
    this.filterChoosen = searchChoose;

    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.authService.getAllMessages(this.paginator.pageIndex, this.perPage, this.filterChoosen);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;    

          this.resultsLength = data['hydra:totalItems']; 
          this.searchresLength = 0;

          return data['hydra:member'];
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => { 
        //console.log({test1111:data})
        //console.log('contenu de data '+ JSON.stringify(data));
        this.messages = [];
        this.lastDate = new Date('01/01/4970');
        data=data.filter(item=>!item.action)
        //console.log({journalComm:data})
        var j = 0;
        var first = false;
        data.forEach(element => {
          j++;
          if(j == 1) first = true;
          this.messages.push(this.dataMap(element, first));
          first = false;
        });

        let afterIsNewDay = false;
        this.messages.reverse().forEach((element, index) => {
          if(afterIsNewDay || index == 0){
            this.messages[index]["afterIsNewDay"] = true;
            afterIsNewDay = false;
            //console.log("afterIsNewDay")
          }
          if(element.isNewDay == true){
            afterIsNewDay = true;
          }
        });

        this.dataSource = this.messages.reverse(); 
         

      });

      merge(this.refreshSearchObs)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.authService.getAllMessages(this.paginator.pageIndex, this.perPage, this.filterChoosenSearch);
        }),
        map(data => {
          this.targetValue = data['hydra:totalItems'];
          console.log("count"+this.targetValue); 
        }),
        catchError(() => {    
          return observableOf([]);
        })
      ).subscribe(data => {    
        if(this.checkSearchInit()) this.animateToValue(0);
        else this.animateToValue(this.targetValue);

      });

  }

  isRoundFirst(afterIsNewDay, isNewDay){
    if(afterIsNewDay && isNewDay){
      return "divcontaine afterIsNewDayBL afterIsNewDayTL";
    }else if(afterIsNewDay){
      return "divcontaine afterIsNewDayBL";
    }else if(isNewDay){
      return "divcontaine afterIsNewDayTL";
    }
    return "divcontaine";
  }

  isRoundLast(afterIsNewDay, isNewDay){
    if(afterIsNewDay && isNewDay){
      return "divcontaine afterIsNewDayBR afterIsNewDayTR";
    }else if(afterIsNewDay){
      return "divcontaine afterIsNewDayBR";
    }else if(isNewDay){
      return "divcontaine afterIsNewDayTR";
    }
    return "divcontaine";
  }

  isNewDay(messageDate){
    let currentDate = new Date(messageDate.substring(0,10)); 
    //console.log('this.lastDate ==> '+this.lastDate);
    //console.log('currentDate ==> '+currentDate);
    if(currentDate < this.lastDate)
    {
      // console.log('Une section ==> '+ currentDate);
      this.lastDate = currentDate;
      return true;
    } 
    else
    { 
      this.lastDate = currentDate; 
      return false;
    }
  }

  getReceiver(recipientid){
    if(this.projectId){
      this.authService.getContactsRecipID(this.projectId, this.tag, recipientid).subscribe(res => {
        var tmpArrayUser = res['hydra:member'];
        this.contactsDest = [];
        for (var i = 0; i < tmpArrayUser.length; i++) {
          if(tmpArrayUser[i].id){
            this.contactsDest.push(tmpArrayUser[i]);
          }
        }
        this.contactsDest.sort(this.compare);
        this.searchRecipientInit = this.contactsDest;
      }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
      });
    } else {
      this.authService.getAllRecipientsBySender(recipientid).subscribe(res => {
        var tmpArrayUser = res['hydra:member'];
        this.contactsDest = [];
        for (var i = 0; i < tmpArrayUser.length; i++) {
          if(tmpArrayUser[i].id){
            this.contactsDest.push(tmpArrayUser[i]);
          }
        }
        this.contactsDest.sort(this.compare);
        this.searchRecipientInit = this.contactsDest;
      }, (error) => {
        //console.log(' getTheProject subscribe error '+error.statusText);
      });
    }
  }

  getContact(recip = false){
    //this.loadContact();

    this.authService.getContactsExpID(this.projectId, this.tag).subscribe(res => {
      this.contactsExp = [];
      var tmpArrayUser = res['hydra:member'];
      for (var i = 0; i < tmpArrayUser.length; i++) {
        this.contactsExp.push(tmpArrayUser[i]);
      }
      this.contactsExp.sort(this.compare);
      this.searchSenderInit = this.contactsExp;
    }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
    });
    if(recip == false){
      this.authService.getContactsRecip().subscribe(res => {
        this.contactsDest = [];
        var tmpArrayUser = res['hydra:member'];
        for (var i = 0; i < tmpArrayUser.length; i++) {
          this.contactsDest.push(tmpArrayUser[i]);
        }
        this.contactsDest.sort(this.compare);
        this.searchRecipientInit = this.contactsDest;
      }, (error) => {
          //console.log(' getTheProject subscribe error '+error.statusText);
      });
    }

  }

  /*loadContact(url?: string){
    url = url ? url : undefined;
    this.authService.getContacts(url).subscribe(res => {
      this.contacts = this.contacts.concat(res['hydra:member']);
      
      if(this.contacts.length && this.contacts[0].id != this.currentUser.id){
        this.contacts.unshift(this.currentUser);
      }
      this.contacts.sort(this.compare);

      if(res['hydra:view']['hydra:next']){
        this.loadContact(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
    }); 
  }*/

  compare( a, b ) {
    if ( a.givenName.toLowerCase() < b.givenName.toLowerCase() ){
      return -1;
    }
    if ( a.givenName.toLowerCase() > b.givenName.toLowerCase() ){
      return 1;
    }
    return 0;
  }

  loadProject(url?: string){
    url = url ? url : undefined;
    this.authService.getProjectsMessages().subscribe(res => {
      this.projectLists = this.projectLists.concat(res['hydra:member']);
      console.log("leeeng "+this.projectLists.length);
      /*
      this.projectLists = this.projectLists.sort(function (a, b) {
          if (a.libelle.toLowerCase() < b.libelle.toLowerCase()) { return -1; }
          if (a.libelle.toLowerCase() > b.libelle.toLowerCase()) { return 1; }
          return 0;
      })
      if(res['hydra:view']['hydra:next']){
        this.loadProject(res['hydra:view']['hydra:next'].replace("/api", ""));
      }*/
      this.projectInit = this.projectLists;
    }); 
  }

  getSujet(idProject){
    this.projectId = idProject;
    //this.search()
    if (idProject) {
      this.authService.getTheProject(idProject).subscribe(res => {
        this.projectTags = res['tags'];
        this.searchTagInit = res['tags'];
        }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
      });

      /*
      this.authService.getContactsExpID(idProject).subscribe(res => {
        this.contactsExp = [];
        this.contactsExp = res['hydra:member'];
        this.contactsExp.sort(this.compare);
      }, (error) => {
      });*/

      /*
      this.authService.getContactsRecipID(idProject).subscribe(res => {
        var tmpArrayUser = res['hydra:member'];
        this.contactsDest = [];
        for (var i = 0; i < tmpArrayUser.length; i++) {
          if(tmpArrayUser[i].id){
            this.contactsDest.push(tmpArrayUser[i]);
          }
        }
        this.contactsDest.sort(this.compare);
      }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
      });*/
    }
  }


  search(){
    if(this.modeDoc){
      this.launchSearchDoc()
    }else{
      this.launchSearch()
    }
  }
  
  launchSearch(ged = null){

    this.modeDoc = false;
    //if(!ged) this.hiddenSearchEngin = true;
    if((this.dateDeadline != null || this.dateDeadlineAfter != null) && (this.statut == null)){
      /*
      alert("statut "+this.statut)
      this.statut = false;
      this.chosenFilter.statut = false;*/
      this.chosenFilter = { 
        transmitter: this.transmitter,
        recipient: this.recipient,
        projectId: this.projectId,
        dateBefore: this.dateBefore,
        dateAfter: this.dateAfter,
        dateDeadline: this.dateDeadline,
        dateDeadlineAfter: this.dateDeadlineAfter,
        tag: this.tag,
        statut: false, 
        type: this.type,
        keyWord: (this.keyWord ? this.keyWord.trim() : null),
        smartNumber: this.smartNumber
      };
    } else {
      this.chosenFilter = { 
        transmitter: this.transmitter,
        recipient: this.recipient,
        projectId: this.projectId,
        dateBefore: this.dateBefore,
        dateAfter: this.dateAfter,
        dateDeadline: this.dateDeadline,
        dateDeadlineAfter: this.dateDeadlineAfter,
        tag: this.tag,
        statut: this.statut, 
        type: this.type,
        keyWord: (this.keyWord ? this.keyWord.trim() : null),
        smartNumber: this.smartNumber
      };
    }
    //alert("this "+this.statut)
    /*
    this.chosenFilter = { 
      transmitter: this.transmitter,
      recipient: this.recipient,
      projectId: this.projectId,
      dateBefore: this.dateBefore,
      dateAfter: this.dateAfter,
      dateDeadline: this.dateDeadline,
      dateDeadlineAfter: this.dateDeadlineAfter,
      tag: this.tag,
      statut: this.statut, 
      type: this.type,
      keyWord: (this.keyWord ? this.keyWord.trim() : null),
      smartNumber: this.smartNumber
    };*/
    
    //this.getAllMessages(this.chosenFilter)
    //console.log(' launchSearch '+ JSON.stringify(this.chosenFilter) );
    //this.fcthiddenSearchEngin();
    this.EventLaunchSearch.emit(this.chosenFilter);

  }

  filterMessageCount(ged = null){

    //this.modeDoc = false;
    //if(!ged) this.hiddenSearchEngin = true;
    if((this.dateDeadline != null || this.dateDeadlineAfter != null) && (this.statut == null)){
      /*
      alert("statut "+this.statut)
      this.statut = false;
      this.chosenFilter.statut = false;*/
      this.filterChoosenSearch = { 
        transmitter: this.transmitter,
        recipient: this.recipient,
        projectId: this.projectId,
        dateBefore: this.dateBefore,
        dateAfter: this.dateAfter,
        dateDeadline: this.dateDeadline,
        dateDeadlineAfter: this.dateDeadlineAfter,
        tag: this.tag,
        statut: false, 
        type: this.type,
        keyWord: (this.keyWord ? this.keyWord.trim() : null),
        smartNumber: this.smartNumber
      };
    } else {
      this.filterChoosenSearch = { 
        transmitter: this.transmitter,
        recipient: this.recipient,
        projectId: this.projectId,
        dateBefore: this.dateBefore,
        dateAfter: this.dateAfter,
        dateDeadline: this.dateDeadline,
        dateDeadlineAfter: this.dateDeadlineAfter,
        tag: this.tag,
        statut: this.statut, 
        type: this.type,
        keyWord: (this.keyWord ? this.keyWord.trim() : null),
        smartNumber: this.smartNumber
      };
    }
    this.refreshSearchObs.next();

  }

  animateToValue(newValue: number) {
    console.log("current searchresLength: " + this.searchresLength);
    if (newValue == this.searchresLength) return;
  
    const step = newValue > this.searchresLength ? 1 : -1;
  
    // Durée totale de l'animation (ici 1 seconde, à ajuster si nécessaire)
    const animationDuration = 1000; // 1000 ms = 1 seconde
    const totalSteps = Math.abs(newValue - this.searchresLength); // nombre d'incréments nécessaires
    const stepDuration = animationDuration / totalSteps; // durée de chaque incrément
  
    let startTime = performance.now(); // Horodatage au début de l'animation
    let lastStepTime = startTime; // Temps du dernier "step" effectué
    let currentStep = 0; // Compteur des étapes réalisées
  
    const animate = (currentTime: number) => {
      const elapsed = currentTime - startTime; // Temps écoulé depuis le début de l'animation
      const progress = Math.min(elapsed / animationDuration, 1); // Calcul du progrès de l'animation
  
      // Nombre d'étapes réalisées jusqu'à maintenant, basé sur le temps écoulé
      currentStep = Math.floor(progress * totalSteps);
  
      // Si une nouvelle étape doit être effectuée
      if (currentTime - lastStepTime >= stepDuration) {
        this.searchresLength = this.searchresLength + step;
        lastStepTime = currentTime; // Mettre à jour le temps du dernier step
  
        console.log("searchresLength: " + this.searchresLength);
  
        // Si la cible est atteinte, on s'assure que la valeur finale est atteinte
        if ((step === 1 && this.searchresLength >= newValue) || (step === -1 && this.searchresLength <= newValue)) {
          this.searchresLength = newValue;
          console.log("Target reached");
        }
      }
  
      // Si l'animation n'est pas encore terminée, on continue l'animation
      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        // À la fin de l'animation, on s'assure que la valeur finale est bien celle attendue
        this.searchresLength = newValue;
      }
    };
  
    // Démarre l'animation
    requestAnimationFrame(animate);
  }

  launchSearchDoc(){
    if(this.type=="http") {
      alert("Vous ne pouvez pas sélectionner le type lien pour rechercher un document");
      return;
    }
    this.modeDoc = true;

    this.chosenFilter = { 
      transmitter: this.transmitter,
      recipient: this.recipient,
      projectId: this.projectId,
      dateBefore: this.dateBefore,
      dateDeadline: this.dateDeadline,
      dateDeadlineAfter: this.dateDeadlineAfter,
      dateAfter: this.dateAfter,
      tag: this.tag,
      statut: this.statut,
      type: this.type, 
      keyWord: (this.keyWord ? this.keyWord.trim() : null),
      smartNumber: this.smartNumber
    };
    //console.log(' launchSearchDoc '+ JSON.stringify(this.chosenFilter) );
    this.fcthiddenSearchEngin();
    this.EventLaunchSearchDoc.emit(this.chosenFilter);

  }

  checkSearchInit(){
    if(this.projectId == null && this.transmitter == null && this.recipient == null && this.dateBefore  == null && this.dateAfter  == null && this.dateDeadline == null && this.dateDeadlineAfter == null && this.tag == null && this.statut == null && this.type == null && this.keyWord == null && this.smartNumber == null){
      return true;
    } else {
      return false;
    }
  }

  public resetSearch(){

    this.transmitter = null;
    this.recipient = null;
    this.projectId = null;
    this.dateBefore  = null;
    this.dateAfter  = null;
    this.dateDeadline = null;
    this.dateDeadlineAfter = null;
    this.tag = null;
    this.statut = null;
    this.type = null;
    this.keyWord = null;
    this.smartNumber = null;
    this.launchSearch("1");
    this.resetSearchFace();

    /*
    if(this.modeDoc == false) 
    {
      this.launchSearch();
    }
    else
    {
      this.launchSearchDoc();
    }*/
    this.getContact();

  }
  
  initProjectFace(item = null){
    this.searchproject = "Par Franchisé";
    this.projectId = null; 
    this.getContact();
    this.initTagFace('1');
    //this.initRecipientFace('1');
    //this.getReceiver(null);
    if(!item) this.selectChoiceFace("init");
    this.filterMessageCount();
  }
  
  initSenderFace(item = null){
    this.searchsenderface = "Par Expéditeur";
    this.transmitter = null; 
    this.getReceiver(null);
    this.initRecipientFace('1');
    if(!item) this.selectChoiceFace("init");
    this.filterMessageCount();
  }

  initRecipientFace(item = null){
    this.searchrecipientface = "Par Destinataire";
    this.recipient = null;
    if(!item) this.selectChoiceFace("init");
    this.filterMessageCount();
  }

  initTypeFace(item = null){
    this.searchtypeface = "Par Type de Document";
    this.type = null;
    if(!item) this.selectChoiceFace("init");
    this.filterMessageCount();
  }

  initTagFace(item = null){
    this.searchthemeface = "Par Thème";
    this.tag = null;
    this.initSenderFace('1');
    //this.initRecipientFace('1');
    this.getContact(true);
    this.getReceiver(null);
    if(!item) this.selectChoiceFace("init");
    this.filterMessageCount();
  }

  initStatusFace(item = null){
    this.searchstatusface = "Par Statut de réponse";
    this.statut = null;
    if(!item) this.selectChoiceFace("init");
    this.filterMessageCount();
  }

  selectChoiceFace(item = "init"){
    if(this.searchstepface == "init" && item == "init") {
      this.fcthiddenSearchEngin();
    } else this.searchstepface = item;
  }

  selectProject(libelle, id){
    this.searchproject = libelle;
    this.projectId = id;
    this.loadFilterElement();
    this.getSujet(id);
    this.selectChoiceFace("init");
    this.filterMessageCount();
    this.getContact(true);
    this.getReceiver(null);
  }

  selectSenderFace(givenname, familyname, id){
    this.searchsenderface = givenname + familyname;
    this.transmitter = id;
    console.log("sender : "+id);
    this.selectChoiceFace("init");
    this.filterMessageCount();
  }

  selectRecipientFace(givenname, familyname, id){
    this.searchrecipientface = givenname + familyname;
    this.recipient = id;
    this.selectChoiceFace("init");
    this.filterMessageCount();
  }

  /*
  selectCopyFace(givenname, familyname, id){
    this.searchcopyface = givenname + familyname;
    this.copy = id;
    this.selectChoiceFace("init");
  }*/

  selectTypeFace(libelle, id){
    this.searchtypeface = libelle;
    this.selectChoiceFace("init");
    this.type = id;
    this.filterMessageCount();
  }

  selectStatusFace(libelle, item){
    this.statut = item;
    this.searchstatusface = libelle;
    this.selectChoiceFace("init");
    this.filterMessageCount();
  }
  
  selectThemeFace(libelle, id){
    this.searchthemeface = libelle;
    this.tag = id;
    this.initSenderFace('1');
    this.initRecipientFace('1');
    this.selectChoiceFace("init");
    this.filterMessageCount();
    this.getContact();
    this.getReceiver(null);
  }

  onDateBeforChange(newDate: any): void {
    this.dateBefore = newDate;
    this.filterMessageCount();
  }

  onDateAfterChange(newDate: any): void {
    this.dateAfter = newDate;
    this.filterMessageCount();
  }

  onDeadlineBeforChange(newDate: any): void {
    this.dateDeadline = newDate;
    this.filterMessageCount();
  }

  onDeadlineAfterChange(newDate: any): void {
    this.dateDeadlineAfter = newDate;
    this.filterMessageCount();
  }

  resetSearchFace(){
    this.searchproject = "Par Franchisé";
    this.searchtypeface = "Par Type de Document";
    this.searchsenderface = "Par Éxpéditeur";
    this.searchrecipientface = "Par Destinataire";
    this.searchcopyface = "Par Membre en Copie";
    this.searchthemeface = "Par Thème";
    this.searchtypeface = "Par Type de Document";
    this.searchdateface = "Par Date";
    this.searchstatusface = "Par Statut de réponse";
    this.searchnumface = "Par Numéro de discussion";
  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }


  public refreshInfoFilter(){
    this.getContact();
    this.loadProject();  
  }

  goBack(){
    this.location.back();
    //this.router.navigate(['/project/'+this.recentProject]);
    //window.history.back();
  }




}


